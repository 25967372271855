.active-bar {
    background: #DE7744;
    border-radius: 20px;
    padding: 2px 5px;
    color: #fff;
    box-shadow: 
        0 2px 5px 0 rgba(0, 0, 0, .16), 
        0 2px 10px 0 rgba(0, 0, 0, .12);
    margin: 5px;
        .b {
            color: #fff;
        }
  }

  .inactive-bar {
    color: #fff;
    border-radius: 20px;
    padding: 2px 5px;
    background: rgba(251, 200, 15, 0.4);
    margin: 5px;
        .b {
            color: #fff;
        }
    }

  .hide {
    display: none !important
  } 