.deep-blue {
  background: #1C3864;
}

.clear-white {
  background: #f5f8fa;
}

.clear-orange {
  background: #DE7744;
}

.deep-orange {
  background: #ec511f;
}

.danger-color {
  color: #e85539
}

.accept-color {
  color: #58bb8a
}

.clear-text-white {
  color: #f5f8fa;
}

.text-orange {
  color: #ec511f;
}

.text-blue {
  color: #1C3864;
}