
.notif-overlay {
    display: inline-flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    // transform: translate(0, -100%);
  }
  
  .notif {
    transition: .3s;
    z-index: 50;
  }
  .notif.hidden {
    transform: translateX(-110%);
    opacity: 0;
  }
  .notif span {
    box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
    display: inline-block;
    background: #404040;
    padding: 16px;
    margin: 0 0 16px 16px;
    border-radius: 3px;
    color: #FFF;
    font: 16px Roboto;
    line-height: 20px;
    white-space: nowrap;
    pointer-events: auto;
  }
  .notif span:first-letter {
    text-transform: uppercase;
  }
  .notif .icon {
    padding-left: 16px;
    color: #a1c2fa;
    text-decoration: none;
    text-transform: uppercase;
  }
  .notif a:hover {
    color: #bbdefb;
  }
  .notif img {
    padding-left: 16px;
    cursor: pointer;
  }

  // .removed {
  //   -webkit-animation: disapear 2s;
  //   -webkit-animation-fill-mode: forwards; 
  //   animation: disapear 2s;
  //   animation-fill-mode: forwards;
  // }
  
  // @-webkit-keyframes disapear{
  //   50% {
  //     -webkit-transform: translateX(-5%);
  //     transform: translateX(-5%);
  //   }
  //   100% {
  //     -webkit-transform: translateX(200%);
  //     transform: translateX(200%); 
  //   }    
  // }
  
  // @keyframes disapear{
  //   50% {
  //     -webkit-transform: translateX(-5%);
  //     transform: translateX(-5%);
  //   }
  //   100% {
  //     -webkit-transform: translateX(200%);
  //     transform: translateX(200%); 
  //   }    
  // }