.bell{
    transform-origin: 70% 1em;
    animation-name: ring;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}


@keyframes ring
  {
    0% { transform: rotate(0deg) }
    30% { transform: rotate(-5deg) }
    60% { transform: rotate(5deg) }
    100% { transform: rotate(0deg) }
  }