$fab-icon-color: #fff;
$fab-size : 70px;
$fab-color: #ec511f;
$fab-item-size : 40px;
$fab-item-color: #ec511f;

.center-error {
  position: relative;
  top: 10em;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.center-content {
  position: absolute;
  color: $fab-item-color;
  left: 50%;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
}

.fab-wrapper {
  position: fixed;
  z-index: 4;
  right: 20px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.fab-button {
  width: $fab-size;
  height: $fab-size;
  background-color: $fab-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;

}

.fab-button div {
  font-size: 30px;
  color: $fab-icon-color;
  text-align: center;
  line-height: $fab-size;
}

.fab-item-list {
  padding: 0px;
  list-style: none;
  position: absolute;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column-reverse;
  transition: all 0.2s ease-in;

  &.activated {
    bottom: $fab-size;
    opacity: 1;
  }

  &.disabled {
    opacity: 0;
    bottom: 0;
  }

}

.fab-item {
  background: $fab-item-color  !important;
  width: $fab-item-size;
  height: $fab-item-size;
  border-radius: 50%;
  text-align: center;
  color: $fab-icon-color  !important;
  margin-bottom: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: 0px 2px 5px rgba(123, 124, 23, 0.3);
  transition: all 0.1s ease-in-out;

  a {
    color: $fab-color  !important;
  }

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }

  .iconColor {
    color: #fff !important;
  }

  .text {
    position: absolute;
    background: $fab-item-color;
    color: #fff !important;
    right: 50px !important;
    margin-top: -26px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 20px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);

  }
}