.views {
    &.withFab{
        background:transparent;    
        animation: fade 1s ease forwards;
        -webkit-animation: fade 1s ease forwards;
        -moz-animation: fade 1s ease forwards;
    }

}

@keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.4;
    }
  }
  