.pulse {
    cursor: pointer;
    box-shadow: 0 0 0 rgba(251, 200, 15, 0.5);
    //animation: pulse 1s infinite;
    
  }
  .pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(251, 200, 15, 0.5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 4px rgba(251, 200, 15, 0.5);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(251, 200, 15, 0.5);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(251, 200, 15, 0.5);
      box-shadow: 0 0 0 0 rgba(251, 200, 15, 0.5);
    }
    70% {
        -moz-box-shadow: 0 0 0 4px rgba(251, 200, 15, 0.5);
        box-shadow: 0 0 0 4px rgba(251, 200, 15, 0.5);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(251, 200, 15, 0.5);
        box-shadow: 0 0 0 0 rgba(251, 200, 15, 0.5);
    }
  }