/* cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
.load-img{
    background: url(../img/lightbox/preloader.gif) 50% no-repeat;
}
.load-img-photos{
    background: url(../img/lightbox/preloader.gif) 50% no-repeat;
}
.deep-blue{
    background: #1C3864;
}
.clear-white{
    background: #f5f8fa;
}
.clear-orange{
    background: #e4af94;
}
.deep-orange{
    background: #ec511f !important;
}
.danger-color{
    color:#e85539
}
.accept-color{
    color:#58bb8a
}
.clear-text-white{
    color: #f5f8fa;
}
.text-orange{
    color: #ec511f !important;
}
.text-blue{
    color: #1C3864;
}
.model-navbar-brand{
    width:auto;
    height: 33px;
}
.text-oo{
    height:auto;
    width:auto;
    font-size: 40px;
}
@media only screen and (max-width: 768px) {
    .model-main-brand {
        width: auto;
        height: 180px;
        margin-left: auto;
        margin-right: auto;
    }
  }
.model-main-brand{
    /* width:110px;height: auto; */
    width:auto;height: 303px;
    margin-left:auto;
    margin-right:auto; 
}
.bg-adapt{
    padding: 5px 10px;
    border-radius: 20px;
    background: rgba(245, 248, 250, 0.7);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.box-shad{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.margin-sides{
    margin-left: auto;
    margin-right: auto;
}
.overflow-options{
    display: -webkit-box;
    flex-wrap: wrap;
    padding-left: 0;
    overflow-x: overlay;
    flex-flow: row nowrap;
    overflow-y: hidden;
    white-space: nowrap;
    list-style-type: none;
    /* padding-left: 20px;
    background-color: rgb(226, 226, 226);
    height: 70px; */
    justify-items: center;
}
.model-a-orange{
    background: #DE7744;
    border-radius: 20px !important;
    padding: 5px !important;
    margin:5px !important;
    color:#fff !important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

.view-frame.ng-enter {
    animation: 0.4s fade-in;
    z-index: 50;
}

.view-frame.ng-leave {
    animation: 0.4s fade-out;
    z-index: 50;
}
.card-big-orange-div{
    border-radius: 8px;
    height:100%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    background: #DE7744!important;
    color:#f5f8fa
}
@keyframes fade-in {
    from { opacity: 0; }
    to   { opacity: 1;}
}

@keyframes fade-out {
    from { opacity: 1;}
    to   { opacity: 0;}
}

.img-grey{
    filter: gray; /* IE5+ */
    -webkit-filter: grayscale(1);
}
.open-sans{
    /* font-family: google-sans; */
    font-family: 'Open Sans';
}

.title-header{
    font-size: 50px;
}
.weight-600{
    font-weight: 600 
}
.width-20{
    width: 20%;
}
.width-60{
    width: 60%;
}
.width-80{
    width: 80%; 
}

.more-weight {
    font-weight: 600;
}

.margin-top-bar{
    margin-top: 75px;
}

.padding-top-bottom{
    padding-top: auto;
    padding-bottom: auto;
}
.margin-top-bottom{
    margin-top: auto;
    margin-bottom: auto;
}
.margin-left-right{
    margin-left: auto;
    margin-right: auto;
}
.font-400{ 
    font-weight: 350 
}
.less-margin{ 
    margin: 5px;
}
.align-text{ 
    text-align: center;
}
.size-90{ 
    font-size: 90% 
}
.item-numbers{
    background: #ec511f;
    border-radius:20px;
    font-weight: 800;
    box-shadow:0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
    margin:20px;
}
.item-box{
    border-width: 0px;
    border-style: solid;
    border-radius: 8px;
    height: 100%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}


.item-numbers h1{
    font-size:300%;
}

.radius-50{
    border-radius: 50px !important;
    font-weight: 800;
}
.opacity-less{
    opacity: 0.6;
}
.gradient-user{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,0.3) 0%, rgba(236,81,31,0.3) 31%, rgba(28,56,100,0.3) 91%);
}
.centered-align{
    text-align: center;
}

.gradient-user{
    position:relative;
    display:inline-block;
    border-radius: 50% !important;
}

.gradient-user:after {
    border-radius: 50% !important;
    content:'';
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    display:inline-block;
    background: -moz-linear-gradient(top, rgba(236,81,31,0.3)  0%, rgba(28,56,100,0.3) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(28,56,100,0.3)), color-stop(100%,rgba(236,81,31,0.3) )); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(236,81,31,0.3)  0%,rgba(28,56,100,0.3) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(236,81,31,0.3)  0%,rgba(28,56,100,0.3) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(236,81,31,0.3)  0%,rgba(28,56,100,0.3) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(236,81,31,0.3)  0%,rgba(28,56,100,0.3) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.gradient-user img{
    border-radius: 50% !important;
    display:block;
}